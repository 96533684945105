<template>
  <transition name="modal">
    <div class="modal-mask" v-if="state.active" :class="classname" @click="eventInhibition">
      <div class="modal-wrapper" @click.self="close">
        <div class="modal-container">

          <!--ここにモーダルウィンドウ内に表示したい内容が描画される-->
          <slot></slot>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
let scrollPositionModal;
export default {
  name: 'vb-modal',
  props: {
    state: {
      active: false,
      is_stop_event: {type: Boolean, required: false, default: false}
    },
    classname: {type: String, default: null},
  },
  methods: {
    eventInhibition(event) {
      if (this.state.is_stop_event) {
        // 未ログイン時にvb-item経由で実行されるログインモーダルでのaタグのイベント伝播を停止
        event.preventDefault();
      }
    },
    close: function () {
      this.state.active = false;
      this.$emit("my-close", this.state.active);
    },
    open: function () {
      this.state.active = true;
    },
    // モーダルがオープンされた場合背景を固定
    bodyFixed: function () {
      scrollPositionModal = $(window).scrollTop();
      $('body').css('position', 'fixed');
      $('body').css('top', '-' + scrollPositionModal + 'px');
    },
    // 背景固定を解除
    bodyFixedNone: function () {
      $('body').css('position', '');
      $('body').css('top', '');
      $(window).scrollTop(scrollPositionModal);
    },
    // フルスクリーンモーダル用のアドレスバーを覗いた高さのカスタムプロパティをセット
    setViewHeight: function () {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.setViewHeight);
  },
  // モーダルの開閉状態をウォッチして背景の固定化を制御
  watch: {
    'state.active': function (status) {
      if (status) {
        this.bodyFixed();
        this.setViewHeight();
      } else {
        this.bodyFixedNone();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-mask.spaced-modal {
  cursor: auto;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity .3s ease;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 100%;
    box-sizing: border-box;
    margin: auto;
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
    // 外側に余白があるスクリーン
    .modal-inner-wrap {
      width: 90%;
      margin: 0 auto;
      background-color: #FFF;

      .button-close {
        display: none;
      }

      .icon-close {
        width: 20px;
        float: right;
        margin: 24px 24px 0 0;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    .modal-container {
      width: fit-content;
      margin: 0px auto;
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;

      .button-close {
        display: none;
      }

      .icon-close {
        width: 20px;
        margin: 24px 24px 0 0;
        float: right;
        cursor: pointer;
      }
    }
    // モーダルの開閉時のエフェクト効果
    .modal-enter {
      opacity: 0;
    }
    .modal-leave-active {
      opacity: 0;
    }
    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

</style>